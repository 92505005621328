<template>
	<div class="page">
		<div class="hx_openLicensedetail_box w1239" v-if="dataobj">
			<div class="block20"></div>
			<div class="titlebox">
				<div class="titlename fontsize30">{{dataobj.title}}</div>
				<div class="btndemand fontsize16" @click="handleShow(true)">提交意向合作需求</div>
			</div>
			<div class="taglist fontsize20">
				<div class="tag" v-if="dataobj.openTypeName"><span>专利类型：</span>{{dataobj.openTypeName}}</div>
				<div class="tag" v-if="dataobj.openTechnologyName"><span>许可技术领域：</span>{{dataobj.openTechnologyName}}</div>
				<div class="tag" v-if="dataobj.applicant"><span>专利权人：</span>{{dataobj.applicant}}</div>
				<div class="tag" v-if="dataobj.patentNo"><span>专利号：</span>{{dataobj.patentNo}}</div>
				<div class="tag" v-if="dataobj.periodDate"><span>许可期限：</span>{{dataobj.periodDate}}</div>
				<div class="tag" v-if="dataobj.openArea"><span>许可范围：</span>{{dataobj.openArea}}</div>
				<div class="tag" v-if="dataobj.payType"><span>收款方式：</span>{{dataobj.payType}}</div>
			</div>
			<div class="block30"></div>
			<div class="condetail">
				<div class="titlediv fontsize24">
					<div class="text">专利摘要</div>
				</div>
				<div class="data_con">
					<div class="fontsize16" v-html="dataobj.patentBody"></div>
				</div>
			</div>
		</div>
		<div class="block58"></div>
		<!-- 弹窗 -->
		<div class="openLdetail_demand" v-if="showModal">
			<div class="zezhaobg"></div>
			<div class="form_demand">
				<div class="closebtn fontsize16" @click="handleShow(false)">X</div>
				<div class="titlename fontsize20">意向合作需求</div>
				<div class="descname fontsize14">请您确认您的信息，后续将有工作人员与您联系协商项目对接事宜</div>
				<div class="formobj fontsize16">
					<div class="label afterstar">姓名：</div>
					<div class="inputbox"><input class="input" v-model="formObj.name" placeholder="请输入您的姓名"/></div>
				</div>
				<div class="formobj fontsize16">
					<div class="label afterstar">联系方式：</div>
					<div class="inputbox"><input class="input" v-model="formObj.phone" placeholder="请输入您的联系方式"/></div>
				</div>
				<div class="formobj fontsize16">
					<div class="label">所在单位：</div>
					<div class="inputbox"><input class="input" v-model="formObj.comName" placeholder="请输入您的所在单位"/></div>
				</div>
				<div class="formobj fontsize16">
					<div class="label">留言：</div>
					<div class="textareabox"><textarea  v-model="formObj.message" class="textarea" placeholder="请输入您的其他留言~"></textarea></div>
				</div>
				<div class="btndiv_box">
					<div class="btn1 fontsize16" @click="handleComfire">确认提交</div>
					<div class="btn2 fontsize16" @click="handleShow(false)">取消</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			uid:0,//详情
			dataobj:null,
			openLicenseList: [8, 8, 8, 8, 8, 8, 8] ,//最新专利
			showModal:false,//弹窗填写
			formObj:{
				comName: "",
				email: "",
				message: "",
				name: "",
				openPatentId: 0,
				openPatentTitle: 0,
				phone: "",
				remarks: "",
			}
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin'])
	},
	methods: {
		//展开弹窗
		handleShow(type){
			this.showModal = type
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/open-patent/getProjectResourceByID', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//提交需求
		handleComfire(){
			var _this = this;
			var params = this.formObj
			if(!params.name){
				this.$message({
				    message: '请输入姓名！',
				    type: 'error',
					offset:388,
					duration:1500
				});
				return;
			}
			if(!params.phone){
				this.$message({
				    message: '请输入手机号码！',
				    type: 'error',
					offset:288,
					duration:1500
				});
				return;
			}
			params["openPatentId"] = this.dataobj.id
			params["openPatentTitle"] = this.dataobj.title
			var loading = this.$loading({
			    lock: true,
			    text: 'Loading',
			    spinner: 'el-icon-loading',
			    background: 'rgba(0, 0, 0, 0.7)'
			});
			// setTimeout(() => {
			//     loading.close();
			// }, 1500);
			this.$http.post('frontEnd/open-patent/onlineApply', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					loading.close();
					_this.$message({
					    message: '提交成功！',
					    type: 'success',
						offset:288,
						duration:1800
					});
					_this.handleShow(false)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss"></style>
